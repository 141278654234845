<template>
    <div class="location-list">
        <div id="map" class="map"></div>
        <div class="search-toolbar">
            <div class="tool-item">
                <span class="item-label">车牌号码：</span>
                <el-input class="item-input" placeholder="请输车牌号码"></el-input>
            </div>
            <div class="tool-item tool-buttons">
                <el-button type="primary">搜索</el-button>
                <el-button>重置</el-button>
            </div>
        </div>
        <div class="table-container">
            <div class="table-wrapper">
                <el-table
                    :cell-style="{textAlign:'center'}"
                    :data="locationList"
                    :header-cell-style="{background:'#409EFF',color:'#fff',textAlign:'center'}"
                >
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        label="车牌号"
                        prop="number"
                    >
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="100"
                    >
                        <template #default="scope">
                            <el-button size="mini">轨迹</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!--<div class="pagination-wrapper">-->
            <!--    <el-pagination-->
            <!--        :total="1000"-->
            <!--        background-->
            <!--        layout="sizes,prev, pager, next,jumper">-->
            <!--    </el-pagination>-->
            <!--</div>-->
        </div>
    </div>
</template>

<script>
import {onMounted, reactive, toRefs} from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
    name: "LocationList",
    setup() {
        let state = reactive({
            locationList: []
        })

        onMounted(() => {
            initMap()
        })

        let initMap = () => {
            AMapLoader.load({
                "key": "7023740654d6ddc40d0332d261beb213",   // 申请好的Web端开发者Key，首次调用 load 时必填
                "version": "1.4.15",                         // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                "plugins": [],                               // 需要使用的的插件列表，如比例尺"AMap.Scale"等
                "AMapUI": {                                  // 是否加载 AMapUI，缺省不加载
                    "version": "1.1",                        // AMapUI 缺省 1.1
                    "plugins": [],                           // 需要加载的 AMapUI ui插件
                },
                "Loca": {                                    // 是否加载 Loca， 缺省不加载
                    "version": "1.3.2"                       // Loca 版本，缺省 1.3.2
                },
            }).then((AMap) => {
                new AMap.Map("map", {});
                searchInMapSelect()
            })
        }

        // 高德地图选中地址
        let searchInMapSelect = () => {
            let map = new AMap.Map("map", {});

            let markers = [
                {
                    icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-1.png",
                    position: [118.7, 32]
                },
                {
                    icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-2.png",
                    position: [118.8, 32]
                },
                {
                    icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-3.png",
                    position: [118.9, 32]
                }
            ];

            markers.forEach((marker) => {
                new AMap.Marker({
                    map: map,
                    icon: marker.icon,
                    position: [marker.position[0], marker.position[1]],
                });
            });
        }

        return {
            ...toRefs(state),
            initMap,
            searchInMapSelect
        }
    },
}
</script>

<style lang="scss" scoped>
.location-list {
    .map {
        height: 600px;
        margin-bottom: 20px;
    }
}
</style>